<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.25"
      width="35.5"
      height="35.5"
      rx="17.75"
      fill="white"
    />
    <path
      d="M19.0344 15.1694V11.6147L22.9004 15.4807L26 18.5803L22.8453 21.2093L19.0344 24.3853V20.9071C12.344 20.4028 10 24.2745 10 24.2745C10 21.8623 10.1988 19.3589 12.0952 17.4633C14.2996 15.2581 17.492 15.102 19.0344 15.1694Z"
      fill="#16ACD9"
    />
    <rect
      x="0.25"
      y="0.25"
      width="35.5"
      height="35.5"
      rx="17.75"
      stroke="#16ACD9"
      stroke-width="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: 'RoundedShareIcon',
}
</script>
