<template>
  <wishlist-products
    v-if="!loading"
    :public-token="publicToken"
  />
</template>

<script>
import useAppConfig from '@/@core/app-config/useAppConfig'
import { onUnmounted } from '@vue/composition-api'
import WishlistProducts from './WishlistProducts.vue'

export default {
  name: 'PublicWishlist',
  components: {
    WishlistProducts,
  },
  data() {
    return {
      publicToken: null,
    }
  },
  created() {
    this.publicToken = this.$route.params.publicToken
  },
  setup() {
    const { isNavMenuHidden } = useAppConfig()

    isNavMenuHidden.value = true

    onUnmounted(() => {
      isNavMenuHidden.value = false
    })
  },
}
</script>
