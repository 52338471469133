<script>
export default {
    name: 'ShopMixin',
    methods: {
        setShowTooltip() {
            const inputWidth = this.$refs.searchInputBox?.offsetWidth
            if (this.isFocusedSearchBar) {
                this.showSearchBarToolTip = false
                return
            }
            if (!inputWidth) return
            this.showSearchBarToolTip = !this.filters.search && inputWidth - 257 < this.searchBarPlaceholderText.length
        },
    },
}
</script>
