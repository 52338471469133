<template>
  <b-dropdown
    v-if="currencies.length"
    class="filter-dropdown switch-currency-dropdown"
    variant="outline-none"
    block
    no-caret
    menu-class="w-100 filter-dropdown-menu scroll-bar"
    no-flip
  >
    <template #button-content>
      <div
        :class="{ 'has-value': !!selectedCurrencyValue }"
        class="d-flex align-items-center justify-content-between w-100"
      >
        <div>
          <span v-if="selectedCurrencyValue"><span class="text-secondary">Currency: </span> {{ selectedCurrencyValue }}</span>
          <span v-else>Select Currency</span>
        </div>
        <feather-icon
          class="ml-1"
          icon="ChevronDownIcon"
          size="16"
        />
      </div>
    </template>
    <b-dropdown-text>
      <b-form-input
        placeholder="Search"
        @input="searchCurrency"
      />
      <div
        v-if="!currencyList.length"
        class="py-2 text-center"
      >
        Nothing was found. Try another currency.
      </div>
    </b-dropdown-text>
    <b-dropdown-item
      v-for="name in currencyList"
      :key="name"
      :active="selectedCurrencyValue === name"
      @click="onSelectCurrency(name)"
    >
      <div>
        <span>
          {{ name }}
        </span>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {
 BDropdown, BDropdownItem, BDropdownText, BFormInput,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
    name: 'CurrencyDropdown',
    components: {
        FeatherIcon, BDropdown, BDropdownText, BDropdownItem, BFormInput,
    },
   props: {
        baseCurrency: {
            type: String,
            default: '',
        },
        currencies: {
            type: Array,
            default: () => [],
        },
        selectedCurrency: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            fullCurrencyList: [],
            currencyList: [],
            selectedCurrencyValue: '',
        }
    },
    watch: {
      currencies: {
            handler() {
              this.loadCurrencies()
            },
            immediate: true,
        },
    },
    emits: ['on-selected-currency'],
    mounted() {
      this.loadCurrencies()
    },
    methods: {
        loadCurrencies() {
          this.fullCurrencyList = [this.baseCurrency, ...this.currencies]
          this.currencyList = this.fullCurrencyList
          this.selectedCurrencyValue = this.selectedCurrency || this.baseCurrency || ''
        },
        searchCurrency(searchString) {
            const searchRegex = new RegExp(searchString, 'i')
            this.currencyList = this.fullCurrencyList.filter(currency => searchRegex.test(currency))
        },
        onSelectCurrency(name) {
            this.selectedCurrencyValue = name
            if (this.selectedCurrencyValue === this.baseCurrency) {
              this.$emit('on-selected-currency', '')
              return
            }
            this.$emit('on-selected-currency', this.selectedCurrencyValue)
        },
    },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';

.switch-currency-dropdown {
  width: 170px;
  border: 1.5px solid $primary !important;
  border-radius: 30px;
  height: 42px;
}
</style>
