<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.300781 1.57143C0.300781 0.703551 1.00433 0 1.87221 0H4.22935C5.09723 0 5.80078 0.703551 5.80078 1.57142V3.90863C5.80078 4.7765 5.09723 5.48005 4.22935 5.48005H1.87221C1.00433 5.48005 0.300781 4.7765 0.300781 3.90863V1.57143Z"
      fill="currentColor"
    />
    <path
      d="M8.55078 1.57143C8.55078 0.703551 9.25433 0 10.1222 0H12.4794C13.3472 0 14.0508 0.703551 14.0508 1.57142V3.90863C14.0508 4.7765 13.3472 5.48005 12.4794 5.48005H10.1222C9.25433 5.48005 8.55078 4.7765 8.55078 3.90863V1.57143Z"
      fill="currentColor"
    />
    <path
      d="M16.8008 1.57143C16.8008 0.703551 17.5043 0 18.3722 0H20.7294C21.5972 0 22.3008 0.703551 22.3008 1.57142V3.90863C22.3008 4.7765 21.5972 5.48005 20.7294 5.48005H18.3722C17.5043 5.48005 16.8008 4.7765 16.8008 3.90863V1.57143Z"
      fill="currentColor"
    />
    <path
      d="M0.300781 9.81653C0.300781 8.94866 1.00433 8.24511 1.87221 8.24511H4.22935C5.09723 8.24511 5.80078 8.94866 5.80078 9.81653V12.1537C5.80078 13.0216 5.09723 13.7252 4.22935 13.7252H1.87221C1.00433 13.7252 0.300781 13.0216 0.300781 12.1537V9.81653Z"
      fill="currentColor"
    />
    <path
      d="M0.300781 18.0914C0.300781 17.2235 1.00433 16.52 1.87221 16.52H4.22935C5.09723 16.52 5.80078 17.2235 5.80078 18.0914V20.4286C5.80078 21.2964 5.09723 22 4.22935 22H1.87221C1.00433 22 0.300781 21.2964 0.300781 20.4286V18.0914Z"
      fill="currentColor"
    />
    <path
      d="M8.55078 9.81653C8.55078 8.94866 9.25433 8.24511 10.1222 8.24511H12.4794C13.3472 8.24511 14.0508 8.94866 14.0508 9.81653V12.1537C14.0508 13.0216 13.3472 13.7252 12.4794 13.7252H10.1222C9.25433 13.7252 8.55078 13.0216 8.55078 12.1537V9.81653Z"
      fill="currentColor"
    />
    <path
      d="M8.55078 18.0914C8.55078 17.2235 9.25433 16.52 10.1222 16.52H12.4794C13.3472 16.52 14.0508 17.2235 14.0508 18.0914V20.4286C14.0508 21.2964 13.3472 22 12.4794 22H10.1222C9.25433 22 8.55078 21.2964 8.55078 20.4286V18.0914Z"
      fill="currentColor"
    />
    <path
      d="M16.8008 9.81653C16.8008 8.94866 17.5043 8.24511 18.3722 8.24511H20.7294C21.5972 8.24511 22.3008 8.94866 22.3008 9.81653V12.1537C22.3008 13.0216 21.5972 13.7252 20.7294 13.7252H18.3722C17.5043 13.7252 16.8008 13.0216 16.8008 12.1537V9.81653Z"
      fill="currentColor"
    />
    <path
      d="M16.8008 18.0914C16.8008 17.2235 17.5043 16.52 18.3722 16.52H20.7294C21.5972 16.52 22.3008 17.2235 22.3008 18.0914V20.4286C22.3008 21.2964 21.5972 22 20.7294 22H18.3722C17.5043 22 16.8008 21.2964 16.8008 20.4286V18.0914Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'CompactGridViewIcon',
}
</script>
