<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.301758"
      y="11.9165"
      width="10.0833"
      height="10.0833"
      rx="2"
      fill="currentColor"
    />
    <rect
      x="12.2168"
      y="11.9165"
      width="10.0833"
      height="10.0833"
      rx="2"
      fill="currentColor"
    />
    <rect
      x="12.2168"
      width="10.0833"
      height="10.0833"
      rx="2"
      fill="currentColor"
    />
    <rect
      x="0.301758"
      width="10.0833"
      height="10.0833"
      rx="2"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'LargeGridViewIcon',
}
</script>
