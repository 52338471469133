<template>
  <b-button
    variant="outline-primary"
    class="filter-date-btn h-100 w-auto font-inter text-nowrap d-flex align-items-center"
    :class="{ 'p-0 pl-50 pr-75' : !isSelectedAll, 'px-1' : isSelectedAll }"
    @click="$emit('toggle-select-all')"
  >
    <feather-icon
      v-if="!isSelectedAll"
      icon="CheckIcon"
      size="18"
      class="mr-50"
    />
    <div>
      {{ isSelectedAll ? 'Deselect all' : 'Select all' }}
    </div>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
    name: 'SelectAllButton',
    components: {
        BButton,
        FeatherIcon,
    },
    props: {
        isSelectedAll: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['toggle-select-all'],
}
</script>
