<template>
  <div
    class="no-products-message d-flex justify-content-center align-items-center"
  >
    <div class="text-center font-weight-bold h2">
      <div>Sorry, no matches were found.</div>
      <div>Try a new search.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyProductsMessage',
}
</script>

<style lang="scss" scoped>
.no-products-message {
  height: inherit;
  min-height: 400px;
}
</style>
